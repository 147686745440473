<template>
    <div class="content">
        <div class="header">
            <router-link to="/salon/antizhulik"
                ><img class="header__logo" src="../../../assets/img/antizhulik/antizhulik-logo.svg" alt=""
            /></router-link>
        </div>
        <div class="main">
            <div class="row">
                <div class="col lg-8 col--centered md-12 tac">
                    <div class="card get">
                        <div class="text_40_xb get__title">
                            Проверить авто
                        </div>
                        <div class="text_24_m">Выберите способ получения гайда</div>
                        <div class="get__form">
                            <form @submit.prevent="submit()" class="user-form user-form--column">
                                <div class="user-form__content">
                                    <div class="form-group" v-for="(control, name) in form" :key="name">
                                        <div class="form-field flex-c">
                                            <div class="form-field__component">
                                                <input
                                                    class="form-group__input"
                                                    v-if="control.type === 'email'"
                                                    type="email"
                                                    required
                                                    :placeholder="control.label"
                                                    v-model="form[name].value"
                                                />
                                                <div class="error" v-if="errorMessages[name]">
                                                    {{ errorMessages[name][0] }}
                                                </div>
                                            </div>
                                            <button type="submit" class="button" :disabled="!isEmailValid">
                                                Получить
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="get__tg text_18_m">
                            Либо заберите свой гайд
                            <span
                                >в
                                <a href="#">
                                    <img src="../../../assets/img/antizhulik/telegram.svg" alt="" />
                                    Telegram
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer text_16_m flex-c">
            © Анти Жулик, 2009 — {{ year }} <a href="#">Пользовательское соглашение</a>
            <ZhulikCallBack></ZhulikCallBack>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import ZhulikCallBack from "@/components/ZhulikCallBack";
export default {
    name: "Atizhulik",
    components: { ZhulikCallBack },
    data() {
        return {
            year: new Date().getFullYear(),
            errorMessages: {},
            form: {
                email: {
                    label: "Ваша эл. почта",
                    type: "email",
                    value: "",
                },
            },
        };
    },
    computed: {
        isEmailValid() {
            let re = new RegExp(/^(.+)@(.+)$/);
            return re.test(this.form.email.value);
        },
    },
    methods: {
        async submit() {
            this.requestStatus = "loading";
            axios.defaults.withCredentials = true;
            let postData = {};
            for (const key in this.form) {
                if (Object.hasOwnProperty.call(this.form, key)) {
                    const element = this.form[key];
                    postData[key] = element.type === "tel" ? "+7" + element.value : element.value;
                }
            }
            axios
                .get("https://api.arlan-auto.ru/api/v1/csrf_token")
                .then(() => {
                    axios
                        .post("https://api.arlan-auto.ru/api/v1/amo/create/lead/salon/car-commission", postData)
                        .then(() => {
                            this.sended = true;
                            this.requestStatus = "";
                        })
                        .catch((e) => {
                            console.log(`e =>`, e);
                            this.requestStatus = "error";
                            if (e.response.status === 422) {
                                this.errorMessages = Object.assign(e.response.data.data);
                            }
                        });
                })
                .catch((e) => {
                    console.log(`e =>`, e);
                    this.requestStatus = "error";
                });
        },
    },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500;700;800&display=swap");
</style>
<style lang="less">
@import url("../../../assets/less/vars.less");
.lg-only {
    @media @sm {
        display: none !important;
    }
}
.sm-only {
    @media @notsm {
        display: none !important;
    }
}
#app.page-antizhulik-get {
    background: #f6f4ff;
    > .header,
    > .footer {
        display: none;
    }
    .text_24_b {
        font-weight: 700;
        font-size: 18px;
    }
    .main {
        padding-top: 65px;
        @media @sm {
            padding-top: 0;
        }
    }
    .button {
        text-decoration: none;
        background: @mainPurple;
        height: 42px;
        padding: 0 12px;
        box-shadow: none;
        font-weight: 500;
        font-size: 16px;
        &[disabled] {
            background: #dedede;
            opacity: 1;
        }
        @media @sm {
            font-size: 13px;
            width: auto;
        }
        &--lg {
            padding: 0 26px;
            height: 65px;
            font-size: 18px;
            @media @sm {
                height: 46px;
                font-size: 16px;
            }
        }
        &--hollow {
            background: none;
            color: @mainPurple;
            border: 1px solid;
        }
    }
    .card {
        background: #fff;
        border-radius: 16px;
        padding: 64px 110px;
        @media @sm {
            padding: 64px 20px;
            border-radius: 0;
            margin-left: -13px;
            margin-right: -13px;
        }
    }
    .page-container {
        height: 100vh;
    }
    .container {
        max-width: 1540px;
        padding-left: 130px;
        padding-right: 130px;
        @media @sm {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    .content {
        font-family: "Inter", sans-serif;
        padding-bottom: 0;
        height: 100%;
        margin-top: 0;
        display: flex;
        flex-direction: column;
        .header {
            margin-top: 40px;
            @media @sm {
                padding: 16px 0;
                margin-top: 0;
            }
            &__logo {
                margin: 0 auto;
                display: block;
                @media @sm {
                    height: 40px;
                }
            }
        }
        .footer {
            margin-top: auto;
            padding: 89px 0 24px;
            color: #999999;
            text-align: left;
            background: none;
            text-align: center;
            @media @sm {
                margin-top: auto;
                padding: 16px 0;
                text-align: center;
            }
            a {
                color: #999999;
                &:hover {
                    color: @mainPurple;
                }
                @media @sm {
                    display: block;
                }
            }
        }
    }
    .get {
        &__title {
            margin-bottom: 24px;
        }
        &__tg {
            padding: 24px;
            background: #f8f8f8;
            margin-top: 64px;
            border-radius: 8px;
            @media @sm {
                margin-top: 40px;
                span {
                    display: block;
                    margin-top: 8px;
                }
            }
            a {
                color: #0088cc;
                text-decoration: none;
                margin-left: 8px;
                img {
                    vertical-align: middle;
                }
            }
        }
        .form-field__component {
            flex-grow: 1;
        }
        .user-form {
            padding: 0;
            width: 100%;
            .button {
                margin: 0;
                margin-left: 20px;
                @media @sm {
                    margin-left: 0;
                    margin-top: 10px;
                }
            }
            @media @sm {
                .form-field {
                    flex-direction: column;
                    justify-content: stretch;
                    align-items: stretch;
                }
            }
        }
    }
}
</style>
